import React from 'react';
import classes from './ProofService.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../../../Assets/images/info-button.png';
import pill from '../../../Assets/images/pills.png';
import unique1 from '../../../Assets/images/link.png';
import unique2 from '../../../Assets/images/research2.png';
import unique3 from '../../../Assets/images/reward.png';
import unique4 from '../../../Assets/images/money-growth.png';

const ProofService = () => {
    return (
        <div>
            <div className={classes.pageBanner}>
                <h2>Royalty & Proof-of-Service</h2>
                <h3>We reinvented the drug development process by implementing ownership proof systems, introducing trust into a complex, trustless network.</h3>
            </div>
            <div className={classes.pageContainer}>
                <Row>
                    <Col xs={12} md={4}><div className={classes.img}><img src={info} /></div></Col>
                    <Col xs={12} md={8}>
                        <div className={classes.title}>Background Information</div>
                        <div className={classes.description}>In 2015, scientific journals published 1.2 million medical research papers, but only 396 potential drugs were submitted to U.S. regulators for permission to begin human testing. HealthChainHack was BlockTEST’s virtual hackathon aiming to channel blockchain innovations into addressing this infamous “Death Valley” of drug R&D. BlockTEST built a decentralized app prototype for this use case in the healthcare industry and invited developers globally to improve upon it as a community.</div>
                        <div className={classes.description}><a href="https://medium.com/@BlockTEST/blocktest-announces-close-of-healthchainhack-c643700a739a" target="_blank">Read more about HealthChainHack</a></div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}><div className={classes.img}><img src={pill} /></div></Col>
                    <Col xs={12} md={8}>
                        <div className={classes.title}>Our Solution</div>
                        <div className={classes.description}>Our dapp is built on Quorum, and allows a drug company to solicit results from research teams using a token-based incentive model. The Drug Company initializes a task with a fixed token salary, and the Research Team which provides the highest-scoring solution is granted this salary.</div>
                        <div className={classes.description}>This token-based incentive system provides drug companies with a traceable, non-monetary way to grant equity to participants in research projects – an ecosystem which can scale to potentially hundreds of parties.</div>
                    </Col>
                </Row>
            </div>
            <div className={classes.pageContainerDarker}>
                <div className={classes.unique}>
                    <div className={classes.title}>Key Functionalities</div>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique1} />
                            <div className={classes.itemTitle}>Connect players in the drug R&D ecosystem</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique2} />
                            <div className={classes.itemTitle}>Maximize research capabilities</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique3} />
                            <div className={classes.itemTitle}>Create & reward drug tasks</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique4} />
                            <div className={classes.itemTitle}>Initialize & scale token economies</div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default ProofService;