import React from 'react';
import classes from './Home.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import offer1 from '../../Assets/images/building.3-2.png';
import offer2 from '../../Assets/images/net_animate.gif';
import offer3 from '../../Assets/images/buildathon-3.png';

const Home = () => {
    return <div>
        <div className={classes.pageBanner}>
            <h2>Optimizing Data-driven Blockchain Solutions</h2>
            <h3>BlockTEST develops and optimizes blockchain solutions to enhance the flow of materials, data, and finance.</h3>
        </div>
        <div className={classes.pageContainer}>
            <div className={classes.offer}>
                <div className={classes.title}>What We Offer</div>
                <Row style={{marginBottom:'6rem'}}>
                    <Col xs={12} md={5}><img src={offer1} /></Col>
                    <Col xs={12} md={7}>
                        <div className={classes.itemTitle}>ADVICE & DIAGNOSTICS</div>
                        <div className={classes.itemDetails}>We analyze your business from start-to-end in order to identify your specific supply chain challenges and opportunities. The result is a targeted system model with an innovative integration roadmap, custom-designed for you.</div>
                        <Link to="/solutions"><div className={classes.btPrimary}>Case Studies</div></Link>
                    </Col>
                </Row>
                <Row style={{marginBottom:'6rem'}}>
                    <Col xs={12} md={5}><img src={offer2} /></Col>
                    <Col xs={12} md={7}>
                        <div className={classes.itemTitle}>PROOF-OF-CONCEPTS
& PRODUCTION-READY SOLUTIONS</div>    
                        <div className={classes.itemDetails}>Our modularized solutions offer the flexibility we need to strategically drive improvement across your supply chain. We’ll develop and iterate to make sure that the solution you receive is optimized to provide the function you need.</div>
                        <Link to="/solutions"><div className={classes.btPrimary}>Our Solutions</div></Link>
                    </Col>
                </Row>
                <Row style={{marginBottom:'6rem'}}>
                    <Col xs={12} md={5}><img src={offer3} /></Col>
                    <Col xs={12} md={7}>
                        <div className={classes.itemTitle}>BLOCKCHAIN DEVOPS PLATFORM
– BLOCKTEST® GREENHOUSE</div>
                        <div className={classes.itemDetails}>Our proprietary blockchain test engine enables you to develop and optimize on multiple platforms. We offer the very best of speed, security, and scalability innovations across protocols and consensus mechanisms.</div>
                        <Link to="/technology"><div className={classes.btPrimary}>Read More</div></Link>
                    </Col>
                </Row>
            </div>
        </div>
        <div className={classes.pageContainerDarker} >
            <div className={classes.mission}>
                <div className={classes.title}>Our Mission</div>
                <div>Founded on the MIT campus in 2018, BlockTEST funnels the vast world of decentralized technologies into secure, scalable, and focused solutions.</div>
                <div className={classes.bold}>Simplify blockchain with BlockTEST.</div>
                <Link to="/about"><div className={classes.btPrimary}>About Us</div></Link>
            </div>
        </div>
    </div>
}

export default Home;