import React from 'react';
import classes from './About.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import team_final from '../../Assets/images/team_final_2.png';
import p1 from '../../Assets/images/jennifer.jpg';
import p2 from '../../Assets/images/kim.jpeg';
import p3 from '../../Assets/images/keman.jpeg';
import p4 from '../../Assets/images/harsh.jpeg';
import p5 from '../../Assets/images/joonho.jpeg';
import p6 from '../../Assets/images/jack.jpeg';
import c1 from '../../Assets/images/c1.png';
import c2 from '../../Assets/images/c2.png';
import c3 from '../../Assets/images/c3.png';
import c4 from '../../Assets/images/c4.jpg';
import c5 from '../../Assets/images/c5.png';
import c6 from '../../Assets/images/c6.png';
import c7 from '../../Assets/images/c7.png';
import c8 from '../../Assets/images/c8.png';
import c9 from '../../Assets/images/c9.png';
import c10 from '../../Assets/images/c10.png';
import c11 from '../../Assets/images/c11.png';
import c12 from '../../Assets/images/c12.png';
import c13 from '../../Assets/images/c13.png';
import c14 from '../../Assets/images/c14.png';
import c15 from '../../Assets/images/c15.png';

const About = () => {
    return <div>
        <div className={classes.pageBanner}>
            <h2>About Us</h2>
        </div>
        <div className={classes.pageContainer}>
            <div className={classes.about}>
                <img src={team_final} className={classes.team}/>
                <div>Founded on the MIT campus in 2018, BlockTEST works to connect enterprises’ needs with the potentials of decentralized innovation. Our team combines decades of experience in finance, consulting, software, analytics, and more. We are passionate about blockchain, and are working to propel the industry towards large-scale adoption.</div>
                <hr/>
                <div className={classes.title}>Credentials</div>
                <Row style={{marginBottom:'6rem'}} className={classes.credentials}>
                    <Col xs={12} md={4}>
                        <img src={c1} />
                        <div className={classes.itemDetails}>2018 MIT-CHIEF Business Competition Top 8 Finalist</div>
                    </Col>  
                    <Col xs={12} md={4}>
                        <img src={c2} />
                        <div className={classes.itemDetails}>Semi-finalist, MIT 100K Competition 2018</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c3} />
                        <div className={classes.itemDetails}>Officially vetted Quorum Tech Ambassador</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c4} />
                        <div className={classes.itemDetails}>2019 SPD Global Fintech Compitition Silver Award</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c5} />
                        <div className={classes.itemDetails}>2019 Singapore Fintech Festival</div>
                    </Col>  
                    <Col xs={12} md={4}>
                        <img src={c6} />
                        <div className={classes.itemDetails}>Finalist (Top 10), Singapore PIER71 Smart Port Challenge 2019</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c7} />
                        <div className={classes.itemDetails}>ETHBoston 2019 Quorum winner</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c8} />
                        <div className={classes.itemDetails}>Admission into New England’s Premier Fintech Accelerator program Jul19-Jul20</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c9} />
                        <div className={classes.itemDetails}>Runner-up, ConsenSys Global Blockchain Competition 2018</div>
                    </Col>  
                    <Col xs={12} md={4}>
                        <img src={c10} />
                        <div className={classes.itemDetails}>Sponsored by Google Startup Access Program</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c11} />
                        <div className={classes.itemDetails}>Blockchain host of drug supply chain use case competition</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c12} />
                        <div className={classes.itemDetails}>Finalist, Enterprise Singapore’s TCC 2019</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c13} />
                        <div className={classes.itemDetails}>Technology Partner, Quorum Adapter</div>
                    </Col>  
                    <Col xs={12} md={4}>
                        <img src={c14} />
                        <div className={classes.itemDetails}><a href="https://www.medtrace.live/" target="_blank">MedTrace</a> Technology Parter</div>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={c15} />
                        <div className={classes.itemDetails}><a href="http://afripera.com/" target="_blank">Afri-PERA</a> Technology Partner</div>
                    </Col>                   
                </Row>
                <hr/>
                <div className={classes.title}>Our Team</div>
                <Row style={{marginBottom:'6rem'}} className={classes.team}>
                    <Col xs={12} md={4}>
                        <img src={p1} class="rounded-circle"/>
                        <div className={classes.itemTitle}>Jennifer Jiang</div>
                        <div className={classes.position}>Co-Founder & CEO</div>
                        <ul className={classes.alignLeft}>
                            <li>MIT M.Sc in Management of Technology (MoT), MBA, MIT Sloan Fellow. </li>
                            <li>Ex-J.P. Morgan Global head of RMB solutions, China head of Commodities</li>
                            <li>Advisor @Harvard CAM Lab,  @ID2020</li>
                        </ul>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={p2} class="rounded-circle"/>
                        <div className={classes.itemTitle}>Kim Yao</div>
                        <div className={classes.position}>Co-Founder</div>
                        <ul className={classes.alignLeft}>
                            <li>Lead Cloud Engineer and data scientist</li>
                            <li>Expert in trust system in trustless environment</li>
                            <li>PhD. In CS. U of Sydney</li>
                        </ul>
                    </Col>  
                    <Col xs={12} md={4}>
                        <img src={p3} class="rounded-circle"/>
                        <div className={classes.itemTitle}>Keman Huang</div>
                        <div className={classes.position}>Co-Founder</div>
                        <ul className={classes.alignLeft}>
                            <li>MIT Research Scientist </li>
                            <li>MIT Post-doc, Tsinghua PhD in CS</li>
                            <li>Cybersecurity and Information Systems expert</li>
                        </ul>
                    </Col>   
                    <Col xs={12} md={4}>
                        <img src={p4} class="rounded-circle"/>
                        <div className={classes.itemTitle}>Harshvardhan Fnu</div>
                        <div className={classes.position}>Co-Founder</div>
                        <ul className={classes.alignLeft}>
                            <li>MIT M. Eng. Center of Transportation & Logistics</li>
                            <li>Supply Chain R&D Specialist</li>
                            <li>10+ years experience as Maritime Officer</li>
                        </ul>
                    </Col>  
                    <Col xs={12} md={4}>
                        <img src={p5} class="rounded-circle"/>
                        <div className={classes.itemTitle}>Joonho Lee</div>
                        <div className={classes.position}>Advisor</div>
                        <ul className={classes.alignLeft}>
                            <li>Kryptoconomy General Partner</li>
                            <li>SVP & Group Chief Information Officer, Fed Reserve Bank of New York​</li>
                            <li>GVP head of Strategy and Operation, Fed Reserve Bank of San Francisco</li>
                        </ul>
                    </Col>    
                    <Col xs={12} md={4}>
                        <img src={p6} class="rounded-circle"/>
                        <div className={classes.itemTitle}>Jack Klinck</div>
                        <div className={classes.position}>Advisor</div>
                        <ul className={classes.alignLeft}>
                            <li>Hyperplane Managing Partner</li>
                            <li>Executive VP and EC member, State Street​</li>
                            <li>Management Committee member & Vice Chairman, BNY Mellon</li>
                        </ul>
                    </Col>  
                </Row>

            </div>
        </div>
    </div>
}

export default About;
