import React from 'react';
import classes from './Financing.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import unique1 from '../../../Assets/images/cross.png';
import unique2 from '../../../Assets/images/document.png';
import unique3 from '../../../Assets/images/accounting.png';
import unique4 from '../../../Assets/images/transfer.png';
import achieve1 from '../../../Assets/images/c9.png';
import achieve2 from '../../../Assets/images/c2.png';
import maritime from '../../../Assets/images/maritime-rescue.png';
import info from '../../../Assets/images/info-button.png';

const Financing = () => {
    return (
        <div>
            <div className={classes.pageBanner}>
                <h2>Financing Maritime Trade</h2>
                <h3>Kivio is using blockchain to unify and streamline the end-to-end process of global maritime trade.</h3>
            </div>
            <div className={classes.pageContainer}>
                <Row>
                    <Col xs={12} md={4}><div className={classes.img}><img src={info} /></div></Col>
                    <Col xs={12} md={8}>
                        <div className={classes.title}>Background Information</div>
                        <div className={classes.description}>The global and remote nature of the global liquid bulk trade encourages information siloes, resulting in long feedback loops and compounding inefficiencies that could well be addressed by digitalization.</div>
                        <div className={classes.description}>Market forces compound the complexity of credit intermediation onto an already complicated ecosystem, adding cost in terms of both time and money.</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}><div className={classes.img}><img src={maritime} /></div></Col>
                    <Col xs={12} md={8}>
                        <div className={classes.title}>About Kivio</div>
                        <div className={classes.description}>Kivio is our solution to reconciling efficiency with security in the maritime liquid bulk industry, offering a replacement for archaic paper-based processes that will save on time and costs while maintaining necessary discretions.</div>
                        <div className={classes.description}>We intend to make the industry more efficient, saving cost and time. Our innovative solutions will lower the barriers to entry and open room for more innovation leading to growth in trade and global prosperity.</div>
                    </Col>
                </Row>
            </div>
            <div className={classes.pageContainerDarker}>
                <div className={classes.title}>Kivio’s End-to-End Solution</div>
                <div className={classes.description}>At its core, Kivio uses blockchain to enable the exchange of cargo ownership and claim rights as digital (tokenized) assets in a digital marketplace. It provides players in the liquid bulk ecosystem with a standardized, interoperable digital platform to connect with counterparties and conduct trade.</div>
            </div>
            <div className={classes.pageContainer}>
                <div className={classes.unique}>
                    <div className={classes.title}>Key Functionalities</div>

                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique1} />
                            <div className={classes.itemTitle}>Provide a unified negotiation platform</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique2} />
                            <div className={classes.itemTitle}>Streamline document management</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique3} />
                            <div className={classes.itemTitle}>Calculate hire & demurrage payments</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique4} />
                            <div className={classes.itemTitle}>Automatically execute fund transfers</div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={classes.pageContainerDarker}>
                <div className={classes.unique}>
                    <div className={classes.title}>Achievements</div>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={achieve1} className={classes.achieve}/>
                            <div className={classes.itemTitle}>Runner-up in the Ethereum Foundation’s <a href="https://media.consensys.net/consensys-academy-announces-winners-of-first-ever-global-mba-blockchain-venture-competition-b417d988523d" target="_blank">ConsenSys Global Blockchain Competition 2018</a></div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={achieve2} className={classes.achieve}/>
                            <div className={classes.itemTitle}>Semi-finalist at the <a href="https://www.mit100k.org/blog/2018/4/13/launch-semi-finalists-announced" target="_blank">MIT 100K Competition 2018</a></div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>

    )
}

export default Financing;