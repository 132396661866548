import React from 'react';
import classes from './Solutions.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import image1 from '../../Assets/images/solution1.png';
import image2 from '../../Assets/images/solution2.png';
import image3 from '../../Assets/images/solution3.png';
import unique1 from '../../Assets/images/research.png';
import unique2 from '../../Assets/images/innovation.png';
import unique3 from '../../Assets/images/roadmap.png';
import unique4 from '../../Assets/images/repair-tools.png';
import { Link } from 'react-router-dom';

const Solutions = () => {
    return <div>
        <div className={classes.pageBanner}>
            <h2>Solutions</h2>
            <h3>Want to know more about our blockchain solutions? Contact us to learn how Blockchain can help enhance your supply chain business flow.</h3>
        </div>
        <div className={classes.pageContainer}>
            <Row>
                <Col md={12} lg={4} style={{ marginBottom: '1rem' }}>
                    <Card className={classes.card}>
                        <Card.Body>
                            <img src={image1} />
                            <div className={classes.cardTitle}>Vaccine Traceability</div>
                            <div className={classes.cardDesc}>The aim of this transparent and innovative digital identity solution is to grant infants in Nigeria a transferable vaccination history and enable NGOs to fund and monitor vaccination initiatives.</div>
                            <Link to="/vaccine-traceability"><div className={classes.btPrimary}>Read More</div></Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} lg={4} style={{ marginBottom: '1rem' }}>
                    <Card className={classes.card}>
                        <Card.Body>
                            <img src={image2} />
                            <div className={classes.cardTitle}>Proof-of-Service</div>
                            <div className={classes.cardDesc}>We reinvented the drug development process for pharmaceutical R&D companies by implementing royalty tokens and ownership proof systems, introducing trust into a complex, trustless network.</div>
                            <Link to="/proof-of-service"><div className={classes.btPrimary}>Read More</div></Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={12} lg={4} style={{ marginBottom: '1rem' }}>
                    <Card className={classes.card}>
                        <Card.Body>
                            <img src={image3} />
                            <div className={classes.cardTitle}>Financing</div>
                            <div className={classes.cardDesc}>We designed Kivio, a blockchain-based platform that will make ocean-freights smarter and more efficient by unifying and streamlining the end-to-end process of global maritime trade.</div>
                            <Link to="/financing-maritime-trade"><div className={classes.btPrimary}>Read More</div></Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
        <div className={classes.pageContainerDarker}>
            <div className={classes.solution}>
                <div className={classes.title}>What Makes Us Unique</div>
                <div className={classes.unique}>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique1} />
                            <div className={classes.itemTitle}>Protocol-Agnostic Testing</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique2} />
                            <div className={classes.itemTitle}>Deep Industry Intelligence</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique3} />
                            <div className={classes.itemTitle}>Full Integration Roadmap</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique4} />
                            <div className={classes.itemTitle}>Proprietary Development Tools</div>
                        </Col>
                    </Row>
                    <Link to="/technology"><div className={classes.btPrimary} style={{marginTop:'3rem'}}>More About Our Technology</div></Link>
                </div>
            </div>
        </div>
    </div>
}

export default Solutions;