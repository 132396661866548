import React from 'react';
import classes from './Technology.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import questions1 from '../../Assets/images/questions-1.png';
import adaptor_animate6 from '../../Assets/images/adaptor_animate6.gif';
import principle1 from '../../Assets/images/principle1.png';
import principle2 from '../../Assets/images/principle2.png';
import principle3 from '../../Assets/images/principle3.png';

const Technology = () => {
    return <div>
        <div className={classes.pageBanner}>
            <h2>Blockchain DevOps Platform – Greenhouse</h2>
            <h3>Building networks and developing robust applications for real-life use cases.</h3>
        </div>
        <div className={classes.pageContainer}>
            <div className={classes.tech}>
                <div className={classes.title}>The Problem of Adoption</div>
                <Row style={{ marginBottom: '6rem' }}>
                    <Col xs={12} md={6}>
                        <div className={classes.itemDetails}>It’s ten years after the initial launch of Bitcoin, and blockchain skeptics emphasize the sparsity of production-ready blockchain software systems that are yet in use. While many await <a href="https://medium.com/@BlockTEST/why-you-shouldnt-be-concerned-with-blockchain-s-killer-app-da53d9e92429" target="_blank">the arrival of blockchain’s next killer app</a>, our focus at BlockTEST is to provide solutions for challenges that specifically face the decentralized community.</div>
                        <blockquote className={classes.blockquote}><p>There is no one “blockchain,” but rather many different blockchains.</p></blockquote>
                        <div className={classes.itemDetails}>
                            How can developers translate innovations across <bold>60+ evolving public protocols, 10+ evolving consensus mechanisms</bold>, at all different business frontiers into <bold>groundbreaking, yet practical solutions</bold> for enterprise and institutional use?
                        </div>
                    </Col>
                    <Col xs={12} md={6}><img src={questions1} /></Col>
                </Row>
            </div>
        </div>
        <div className={classes.pageContainerDarker} >
            <div className={classes.tech}>
                <div className={classes.title}>Many blockchains, one adaptor</div>
                <div className={classes.itemDetails}>Our blockchain adaptor is a cloud-based blockchain performance evaluation engine. It provides a standardized private environment in which to develop, deploy, and test blockchain proof-of-concepts across varying public chain platforms.</div>
                <div style={{textAlign: 'center'}}><img src={adaptor_animate6} style={{ width: '60%', maxWidth:'500px' }} /></div>
                <div className={classes.alignLeft}>
                    <div className={classes.bold}>Use our adaptor to validate blockchain solutions:</div>
                    <ul>
                        <li>Evaluate project syntax, performance, security, scalability, & more.</li>
                        <li>Platform agnostic – currently supports Ethereum, Hyperledger Fabric, Quorum, and NEO.</li>
                        <li>Private testing environment ensures that performance metrics are unbiased.</li>
                        <li>Full API development for seamless integration with legacy systems.</li>
                        <li>Continuous POC iteration – no more hassles from ongoing protocol upgrades.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={classes.pageContainer}>
            <div className={classes.tech}>
                <div className={classes.title}>Our Principles</div>
                <Row style={{ marginBottom: '6rem' }} className={classes.principles} >
                    <Col xs={12} md={4}>
                        <img src={principle1} />
                        <div className={classes.itemTitle}>STANDARDIZATION</div>
                        <div className={classes.itemDetails}>Establishing performance benchmarking with transparent, measurable standards</div>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={principle2} />
                        <div className={classes.itemTitle}>MODULARIZATION</div>
                        <div className={classes.itemDetails}>Finding commonalities between industries to apply core blockchain concepts across verticals</div>
                    </Col>
                    <Col xs={12} md={4}>
                        <img src={principle3} />
                        <div className={classes.itemTitle}>INTEROPERABILITY</div>
                        <div className={classes.itemDetails}>Providing insights on the impact of varying architecture, protocol, and consensus mechanisms</div>
                    </Col>
                </Row>
            </div>
        </div>
        <div className={classes.pageContainerDarker} >
            <div className={classes.tech}>
                <div className={classes.title}>Try it out</div>
                <Row>
                    <Col xs={12} md={6}>
                        <div className={classes.itemTitle}>GREENHOUSE</div>
                        <div className={classes.itemDetails}>
                            Test out our technology for free with Greenhouse, our user-centric blockchain development platform. Greenhouse supports and connects users at every stage of the blockchain development.
                        </div>
                        <a href="https://greenhouse.blocktest.com" target="_blank" ><div className={classes.btPrimary} style={{marginBottom:'1rem'}}>Visit Greenhouse</div></a>
                    </Col>
                    <Col xs={12} md={6}>
                        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/yIMDkaGXGb0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/yIMDkaGXGb0" frameborder="0" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
}

export default Technology;