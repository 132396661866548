import React from 'react';
import classes from './Header.module.scss';
import logo from '../../Assets/images/Logo.png';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return <div className={classes.pageContainer}>
        {/* <img className={classes.logo} src={logo} /> */}
        <Navbar bg="light" expand="lg" variant="light" className="justify-content-between">
            <Navbar.Brand href="/home">
                <img className={classes.logo} src={logo} alt="BlockTEST Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav className="justify-content-end">
                    {/* <Nav.Link href="/home">Home</Nav.Link>
                    <Nav.Link href="/solutions">Solutions</Nav.Link>
                    <Nav.Link href="/technology">Technology</Nav.Link>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="https://greenhouse.blocktest.com">Greenhouse</Nav.Link> */}
                    <div className={classes.nav}>
                        <NavLink to="/home" activeClassName={classes.navActive}>Home</NavLink>
                    </div>
                    <div className={classes.nav}>
                        <NavLink to="/solutions" activeClassName={classes.navActive}>Solutions</NavLink>
                    </div>
                    <div className={classes.nav}>
                        <NavLink to="/technology" activeClassName={classes.navActive}>Technology</NavLink>
                    </div>
                    <div className={classes.nav}>
                        <NavLink to="/about" activeClassName={classes.navActive}>About</NavLink>
                    </div>
                    <div className={classes.nav}>
                        <a href="https://greenhouse.blocktest.com" target="_blank" activeClassName={classes.navActive}>Greenhouse</a>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </div>
}

export default Header;