import React from 'react';
import classes from './Footer.module.scss';

const Footer = () => {
    return <div className={classes.pageContainer}>
        <div style={{ marginBottom: '1rem' }}><b>BlockTEST, LLC</b></div>
        <div className={classes.spaceBetween}>
            <div>
                <div style={{ marginBottom: '1rem' }}>
                    C/O DCU Fintech Innovation Center<br></br>
                    695 Atlantic Ave, Boston, MA 02111
                </div>
                <div className={classes.socialMedia}>
                    contact@blocktest.net
                <a target="_blank" href="https://www.facebook.com/BlockTESTofficial/"><i className="fab fa-facebook-f"></i></a>
                    <a target="_blank" href="https://twitter.com/BlocktestHQ"><i className="fab fa-twitter"></i></a>
                    <a target="_blank" href="https://www.linkedin.com/company/blocktest"><i className="fab fa-linkedin-in"></i></a>
                    <a target="_blank" href="https://t.me/BlockTestOfficial"><i className="fab fa-telegram-plane"></i></a>
                    <a target="_blank" href="https://medium.com/@BlockTEST"><i className="fab fa-medium-m"></i></a>
                </div>
            </div>
            <div>
                <div style={{ marginBottom: '1rem' }}>
                    <a target="_blank" href="/terms-of-service"><div>Terms of Service</div></a>
                    <a target="_blank" href="/privacy-policy"><div>Privacy Policy</div></a>
                </div>
                <div>© BlockTEST LLC. All rights reserved.</div>
            </div>
        </div>
    </div>
}

export default Footer;