import React from 'react';
import classes from './style.module.scss';

const Policy = () => {
    return (
        <div>
            <div className={classes.pageBanner}>
                <h2>Privacy Policy</h2>
            </div>
            <div className={classes.pageContainer}>
                <p>Effective Date: February 7, 2019</p>
                <p>This website is operated by BlockTEST LLC, headquartered at 1000 Massachusetts Avenue, Cambridge, Massachusetts 02138 in the United States, and referred to as “BlockTEST,” “we,” “us,” and “our” below. This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information. This Privacy Policy governs your use of BlockTEST.com and Greenhouse.BlockTEST.com; all communication tools we provide, including e-mail, messaging, forums, and other content delivery methods; our products, services, data, content, information, and applications (collectively, “BlockTEST Services”) and constitute a binding Agreement between you (“You” and “Your”) and us regarding the BlockTEST Services. By using or accessing the BlockTEST Services in any way, You accept and agree to be bound by all of these Terms.  These Terms will remain in effect at all times while You use BlockTEST Services and with respect to all information obtained from or through BlockTEST Services.</p>
                <p>When you clicked “accept” or “agree” in connection with registering your account, we made available to you this Privacy Policy and the information handling practices described in here. This Privacy Policy only covers BlockTEST’s practices regarding personal information, and not information more generally. We may use aggregated, anonymized data that we derived from your personal information before you deleted it, but not in a manner that incorporates any of your personal information or would identify you personally.</p>
                <p>We’ll ask for your consent before using your information for a purpose that isn’t covered in this Privacy Policy. You may, of course, decline to share certain personal information with BlockTEST, in which case BlockTEST may not be able to provide to you some of the features and functionality found on BlockTEST services.</p>
                <p><strong>Information BlockTEST Collects</strong></p>
                <p>We collect information to provide better Services to all our users. When you’re not signed into a BlockTEST account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. When you’re signed in, we collect information that we store with your BlockTEST account.</p>
                <p><em>Things You Create or Provide to Us</em></p>
                <p>When you create a BlockTEST account, create new Solutions or Challenges or otherwise use BlockTEST services, you provide us with personal information that includes your name and a password. You may also provide your mailing address and home/mobile phone number, or other personal information.</p>
                <p>Your provision of this personal data is required for you to enter into the <a href="https://blocktest.com/terms-of-service/"><strong>Terms of Service</strong></a>, a contract, with us, as well as for you to submit to Challenges hosted on BlockTEST services. These entries are also a contract between you and the Challenge creator, and we need your personal information to host BlockTEST services offered under that contract (see the <a href="https://blocktest.com/terms-of-service/"><strong>Terms of Service</strong></a> for additional information). If you do not provide the minimum amount of personal data required to register an account, including your name and email address, you cannot use BlockTEST services, as this information is necessary for contractual reasons. Please see “European Requirements” below for further information on the bases for our processing your personal information.</p>
                <p>We also collect the content you create, upload, or receive from others when using our Services. This includes Solution and Challenge submissions.</p>
                <p><em>Information We Collect as You Use Our Services</em></p>
                <p>Your apps, browsers &amp; devices</p>
                <p>We collect information about the apps, browsers, and devices you use to access our Services or open one of our HTML emails by using different types of technology, including “clear gifs” or “web beacons.” This “automatically collected” information may include Internet Protocol address (also known as “IP address”) or other device address or ID, web browser or device type, the web pages or sites that you visit just before or just after BlockTEST services, the pages you view on BlockTEST services, and the dates and times that you visit BlockTEST services.</p>
                <p>We use various technologies to collect and store information, including cookies, pixel tags, local storage, such as browser web storage or application data caches, databases, and server logs.</p>
                <p>Your activity</p>
                <p>We collect information about your activity in our Services, which may include:</p>
                <ul>
                    <li>Views and interactions with content and ads.</li>
                    <li>People with whom you communicate or share content.</li>
                    <li>Cookies and web beacons</li>
                </ul>
                <p>When you use BlockTEST services, we send one or more cookies – small text files containing a string of alphanumeric characters – to your computer. For example, we use cookies to ensure proper navigation between pages on BlockTEST services. BlockTEST may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to BlockTEST services.</p>
                <p>Persistent cookies can be removed. Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. If you delete, or choose not to accept, cookies from BlockTEST services, you may not be able to utilize the features of BlockTEST services to their fullest potential.</p>
                <p>We may also implement on BlockTEST services third party content, such as advertising or analytic services, that uses “clear gifs,” “web beacons,” or other similar techniques, which allow the third party content provider to read and write cookies to your browser or implement similar tracking mechanisms. This information is collected directly by the third party, and BlockTEST does not participate in that data transmission. Information collected by third parties in this manner is subject to that third party’s own data collection, use, and disclosure policies. We currently implement services provided by Google Analytics, Facebook, and Twitter.</p>
                <p>Linked accounts</p>
                <p>When you link an account you may have on a third party service (such as a third party social networking site or email provider) to your BlockTEST account, BlockTEST collects certain information stored in connection with that account that you have configured that service to make available, including your email address, provider ID, first and last name, and profile picture. By linking your accounts in this manner, you authorize BlockTEST to access and use your account on the third party service in connection with your use of BlockTEST services.</p>
                <p><strong>Why BlockTEST Collects Information</strong></p>
                <p>BlockTEST uses the information we collect for the following purposes:</p>
                <p><em>Provide Our Services</em></p>
                <p>We use your information to deliver our Services, like administering Challenges you participate in or hosting Solutions you upload or access. For example, we use the IP address assigned to your device to send you the data you requested, such as loading a Solution. Another example is that we use unique identifiers stored in cookies on your device to help us authenticate you as the person who should have access to your BlockTEST account.</p>
                <p><em>Maintain &amp; Improve Our Services</em></p>
                <p>We also use your information to ensure our Services are working as intended, such as tracking outages or troubleshooting issues that you report to us. For example, we continuously monitor our systems to look for problems. And if we find something wrong with a specific feature, reviewing activity information collected before the problem started allows us to fix things more quickly.</p>
                <p>And we use your information to make improvements to our Services – for example, to understand and analyze the usage trends and preferences of our BlockTEST account members and to improve the way BlockTEST services work and look.</p>
                <p>For example, we use cookies to analyze how people interact with our Services, which can help us build better products. It may help us discover that it’s taking people too long to complete a certain task or that they have trouble finishing steps at all. We can then redesign that feature and improve the product for everyone.</p>
                <p><em>Develop New Services</em></p>
                <p>We use the information we collect in existing Services to help us create new features and functionality.</p>
                <p><em>Provide Personalized Services</em></p>
                <p>We use the information we collect to customize our Services for you, including remembering your information so that you will not have to log-in again the next time you visit BlockTEST services if you haven’t signed out. We also use data to provide customized third party content and information and to track your status in Solutions, Challenges or other activities. In addition, we may use the information we collect to provide personalized and relevant advertising.</p>
                <p><em>Measure Performance</em></p>
                <p>We use data for analytics and measurement to understand how our Services are used. For example, we analyze data about your visits to our sites to do things like optimize product design, monitor and analyze the operation and effectiveness of BlockTEST services and related activities, and monitor aggregate site usage metrics such as total number of visitors and pages viewed. We use a variety of tools to do this, including Google Analytics. Google Analytics relies on first-party cookies, which means the cookies are set by BlockTEST. You can opt-out from Google Analytics by clicking <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>.</p>
                <p><em>Communicate with You</em></p>
                <p>We use information we collect, like your email address, to interact with you directly. For example, we may send you a notification if we detect suspicious activity on your account, or we may let you know about upcoming changes or improvements to our Services. We will also use your email address to contact you with respect to Challenge participation, prize awards and otherwise in connection with Challenges; for administrative and customer service purposes; to address intellectual property infringement, rights of privacy, or defamation issues; or regarding things you post on BlockTEST services. And if you contact BlockTEST, we’ll keep a record of your request to help solve any issues you might be facing.</p>
                <p>BlockTEST also uses your email address or other personal information to send you messages about BlockTEST services and communications from other Members. We may also send you messages related to BlockTEST services and the activities of third parties we work with. These messages may be targeted at you based on your activities on our Services, for example your interaction with a particular Solution or your participation with a specific Challenge. You will have the ability to opt-out of receiving any such communication.</p>
                <p><em>Protect BlockTEST, Our Users, and the Public</em></p>
                <p>We use information to help improve the safety and reliability of our Services. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm BlockTEST, our users, or the public. For example, we collect and analyze IP addresses and cookie data to protect against automated abuse. This abuse can take many forms, such as sending spam to BlockTEST users or launching a Distributed Denial of Service (DDoS) attack.</p>
                <p>When we detect spam, malware, illegal content, and other forms of abuse on our systems in violation of our policies, we may disable your account or take other appropriate action. In certain circumstances, we may also report the violation to appropriate authorities.</p>
                <p><strong>Sharing Your Information</strong></p>
                <p><em>When You Share Your Information</em></p>
                <p>Some of our Services let you share information with other people, and you have control over how you share. Any information that you voluntarily choose to include in an area of BlockTEST services accessible to other BlockTEST members, such as a public profile page or in a Challenge, will be available to other BlockTEST members who access that content. This also applies to information you choose to make available through features that connect to third party services, if applicable.</p>
                <p>Also, if you communicate directly with another BlockTEST member, any information that you voluntarily choose to include in such a communication with other BlockTEST members will be available to such other members. Once you make your information available in any of these ways, it could be collected and used by the recipients without restriction. We urge BlockTEST users to exercise common sense, prudence and good judgment about what information to make available to others through BlockTEST services.</p>
                <p><em>When BlockTEST Shares Your Information</em></p>
                <p>We are not in the business of selling your information. We do, however, disclose your personal information in connection with developing and maintaining BlockTEST services and operating Challenges. We share your personal information in the following cases:</p>
                <p>With your consent</p>
                <p>We’ll share personal information outside of BlockTEST when we have your consent. For example, if you give us permission to share your information with a third party who may be a prospective employer.</p>
                <p>To operate Challenges</p>
                <p>BlockTEST may disclose your personal information to the creators of Challenges to enable your participation in the Challenges and our operation of the Challenges. The terms of that disclosure are covered in the Challenge rules, which is a contract between you and the competition sponsor.</p>
                <p>For external processing</p>
                <p>We provide personal information to our affiliates and other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures. For example, we use service providers to help us with customer support. These third parties may have access to or process your personal information as a result of performing the services they were engaged to perform.</p>
                <p>For legal reasons</p>
                <p>We will share personal information outside of BlockTEST if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to:</p>
                <ul>
                    <li>Meet any applicable law, regulation, legal process, or enforceable governmental request.</li>
                    <li>Enforce our <strong><a href="https://blocktest.com/terms-of-service/">Terms of Use</a></strong>, including investigation of potential violations, and take precautions against liability.</li>
                    <li>Detect, prevent, or otherwise address fraud, abuse, security, or technical issues.</li>
                    <li>Protect against harm to the rights, property or safety of BlockTEST or our affiliates, our users, or the public as required or permitted by law.</li>
                    <li>Protect the security or integrity of BlockTEST services and to protect or enforce third party rights.</li>
                </ul>
                <p>We may share non-personally identifiable information publicly and with our partners — like publishers, advertisers, developers, or rights holders. For example, we share information publicly to show trends about the general use of our Services.</p>
                <p>If BlockTEST is involved in a merger, acquisition, or sale of assets, we’ll continue to ensure the confidentiality of your personal information and give affected users notice before personal information is transferred or becomes subject to a different privacy policy.</p>
                <p><strong>Our Commitment to Data Security</strong></p>
                <p>BlockTEST uses a variety of physical, managerial, and technical safeguards designed to improve the security of our systems and your personal information. We cannot, however, ensure or warrant the security of any information you transmit to BlockTEST, nor can we guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. You transfer your information to BlockTEST at your own risk.</p>
                <p>If BlockTEST learns of a security breach, we will notify you in accordance with applicable law.</p>
                <p><strong>Third Party Websites</strong></p>
                <p>BlockTEST services contain links to websites and services provided by third parties. Any personal information you provide on third party sites or services is provided directly to that third party and is subject to that third party’s policies governing privacy and security. We are not responsible for the content or privacy and security practices and policies of third party sites or services to which links are displayed on BlockTEST services. We encourage you to learn about third parties’ privacy and security policies before providing them with personal information.</p>
                <p><strong>Our Commitment to Children’s Privacy</strong></p>
                <p>Protecting the privacy of children is especially important. For that reason, BlockTEST does not knowingly collect or maintain personal information from persons under 13 years old, and no part of BlockTEST services is directed to persons under 13. IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS BLOCKTEST SERVICES AT ANY TIME OR IN ANY MANNER.</p>
                <p>If BlockTEST learns that personal information of children under 13 years old has been collected on BlockTEST services without verifiable parental consent, then BlockTEST will take the appropriate steps to delete this information. If you are a parent or guardian and discover that your child under the age of 13 has obtained a user account on BlockTEST services, then you may alert BlockTEST at <a href="mailto:contact@blocktest.net">contact@blocktest.net</a> and request that BlockTEST delete that child’s personal information from its systems.</p>
                <p>If you are between the ages of 13 and 16 years old, you may not use BlockTEST services until BlockTEST has received verifiable parental consent for your use of BlockTEST services. If you are between the ages of 13 and 17 years old or under the age of contractual majority in your country, you also may not enter any competitions until BlockTEST has received verifiable parental consent for your entry into a specific competition. Please check the eligibility rules for each competition to verify that minors are allowed to participate and ensure that the parental consent form is on file for each competition. For more information on the parental consent process reach out to BlockTEST at <a href="mailto:contact@blocktest.net">contact@blocktest.net</a>.</p>
                <p><strong>Compliance &amp; Cooperation with Regulators</strong></p>
                <p>We regularly review this Privacy Policy and make sure that we process your information in ways that comply with it.</p>
                <p><em>Data Transfers</em></p>
                <p>Your information may be processed on servers located outside of the country where you live. Data protection laws vary among countries, with some providing more protection than others. Regardless of where your information is processed, we apply the same protections described in this policy. We transfer your personal data from the European Union to the United States in accordance with standard contractual clauses we entered into. The standard contractual clauses can be read <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32004D0915" target="_blank">here</a>.</p>
                <p>When we receive formal written complaints, we respond by contacting the person who made the complaint. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of your data that we cannot resolve with you directly.</p>
                <p><em>European Requirements</em></p>
                <p>If European Union (EU) data protection law applies to the processing of your information, you can exercise your right to request access to, update, remove, and restrict the processing of your information. You also have the right to object to the processing of your information or export your information to another service. Please reach out to us at <a href="mailto:contact@blocktest.net">contact@blocktest.net</a> to exercise these rights.</p>
                <p>If you have any questions, you may contact us at <a href="mailto:contact@blocktest.net">contact@blocktest.net</a>. If you have concerns regarding your rights under local law, you can contact your local data protection authority.</p>
                <p>We process your information for the purposes described in this policy, based on the following legal grounds:</p>
                <p>With your consent</p>
                <p>We ask for your agreement to process your information for specific purposes and you have the right to withdraw your consent at any time<strong>.</strong></p>
                <p>When we’re pursuing legitimate interests</p>
                <p>We process your information for our legitimate interests while applying appropriate safeguards that protect your privacy. This means that we process your information for things like:</p>
                <ul>
                    <li>Providing, maintaining, and improving our Services to meet the needs of our users</li>
                    <li>Developing new products and features that are useful for our users</li>
                    <li>Understanding how people use our Services to ensure and improve the performance of our Services</li>
                    <li>Customizing our Services to provide you with a better user experience</li>
                    <li>Marketing to inform users about our Services</li>
                    <li>Providing advertising to make many of our Services freely available for users</li>
                    <li>Detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with our Services</li>
                    <li>Protecting against harm to the rights, property or safety of BlockTEST, our users, or the public as required or permitted by law</li>
                    <li>Performing research that improves our Services for our users and benefits the public</li>
                    <li>Fulfilling obligations to our partners like developers and rights holders</li>
                    <li>Enforcing legal claims, including investigation of potential violations of our<strong> <a href="https://blocktest.com/terms-of-service/">Terms of Use</a></strong></li>
                </ul>
                <p>When we’re providing a service</p>
                <p>We process your data to provide a service you’ve asked for under a contract. For example, we process your information in connection with Challenges you participate in.</p>
                <p>When we’re complying with legal obligations</p>
                <p>We’ll process your data when we have a legal obligation to do so, for example, if we’re responding to legal process or an enforceable governmental request.</p>
                <p><strong>Changes and Updates to this Privacy Policy</strong></p>
                <p>We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. Note that our amended Privacy Policy will become effective on a going-forward basis as stated in the Terms, except that (i) unless you agree otherwise, we will use your personal information in the manner described in the Privacy Policy in effect when we received that information and (ii) if you do not agree with any changes to the Privacy Policy, you must terminate your BlockTEST membership and cease use of BlockTEST services. Your continued use of BlockTEST services after a revised Privacy Policy has become effective indicates that you have read, understood and agreed to the current version of the Privacy Policy.</p>
                <p><strong>BlockTEST Contact Information</strong></p>
                <p>Please contact BlockTEST with any questions or comments about this Privacy Policy, your personal information, our use and disclosure practices, or your consent choices through <a href="mailto:contact@blocktest.net">contact@blocktest.net</a>.</p>
            </div>
        </div>
    )
}

export default Policy;

