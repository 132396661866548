import React from 'react';
import classes from './VaccineTrace.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../../../Assets/images/info-button.png';
import idea from '../../../Assets/images/idea.png';
import unique1 from '../../../Assets/images/analysis.png';
import unique2 from '../../../Assets/images/clock.png';
import unique3 from '../../../Assets/images/patient.png';
import unique4 from '../../../Assets/images/savings.png';
import partner1 from '../../../Assets/images/c15.png';
import partner2 from '../../../Assets/images/sustainable-development.png';

const VaccineTrace = () => {
    return (
        <div>
            <div className={classes.pageBanner}>
                <h2>Vaccine Traceability</h2>
                <h3>A solution whose aim is to grant infants a transferable vaccination history and enable NGOs to fund and monitor vaccination initiatives.</h3>
            </div>
            <div className={classes.pageContainer}>
                <Row>
                    <Col xs={12} md={4}><div className={classes.img}><img src={info} /></div></Col>
                    <Col xs={12} md={8}>
                        <div className={classes.title}>Background Information</div>
                        <div className={classes.description}>Nigeria is the most populated country in Sub-Saharan Africa with over 200 million people, 45% of whom are under 15 years old. In Nigeria, vaccine-preventable diseases are the most common cause of childhood death, about 3 million per year.</div>
                        <div className={classes.description}>The current vaccination record keeping system is fragmented, presenting the risk of accidental repeat vaccinations and overdose. Opaqueness in consumption tracking also results in inadequate supplies of vaccines to different parts of the country. Furthermore, fraud and fund diversions due to lack of visibility of key program transactions deter funding organizations from supporting vaccination programs in developing nations.</div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}><div className={classes.img}><img src={idea} /></div></Col>
                    <Col xs={12} md={8}>
                        <div className={classes.title}>Opportunity</div>
                        <div className={classes.description}>To work towards reducing vaccine-preventable diseases in Nigeria, <b>BlockTEST</b>, a US technology company, and <b>AfriPERA</b>, a Nigeria-based social impact consortium working with local governments, public and private health facility operators, have partnered to develop a blockchain-powered solution, “VaccineTrace.”</div>
                        <div className={classes.description}>This token-based incentive system provides drug companies with a traceable, non-monetary way to grant equity to participants in research projects – an ecosystem which can scale to potentially hundreds of parties.</div>
                    </Col>
                </Row>
            </div>
            <div className={classes.pageContainerDarker}>
                <div className={classes.title}>Our Solution</div>
                <div className={classes.description}>VaccineTrace uses smart contracts to record drug dose and drug administration data, preventing the duplicate consumption of vaccinations.</div>
                <div className={classes.blockquote}><p>VaccineTrace can establish a transparency-based trust economy between the UN, government and regulatory bodies, and other intermediary organizations and benefactors of vaccination programs.</p></div>
                <div className={classes.description}>A key objective of this project is to significantly reduce barriers to capital inflow for vaccination programs in Nigeria. Thus, VaccineTrace can also proposes automated payment contracts to streamline transactions between key program stakeholders by linking payment processes to a number of agreed project milestones.</div>
                <div className={classes.description}>Such a trust economy would significantly increase barriers for fraud and thereby improve funding confidence amongst organizations who operate immunization programs in Nigeria and other developing countries.</div>
            </div>
            <div className={classes.pageContainer}>
                <div className={classes.unique}>
                    <div className={classes.title}>Key Functionalities</div>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique1} />
                            <div className={classes.itemTitle}>Access to population immunization rates</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique2} />
                            <div className={classes.itemTitle}>Automated vaccination due date forecasting</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique3} />
                            <div className={classes.itemTitle}>Streamlined patient outreach</div>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <img src={unique4} />
                            <div className={classes.itemTitle}>Funding linked to vaccine schedule</div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={classes.pageContainerDarker}>
                <div className={classes.unique}>
                    <div className={classes.title}>Partners</div>
                    <Row>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <a href="http://afripera.com/" target="_blank"><img src={partner1} className={classes.partner}/></a>
                        </Col>
                        <Col xs={12} md={6} style={{ marginBottom: '2rem' }}>
                            <a href="https://www.un.org/sustainabledevelopment/sustainable-development-goals/" target="_blank"><img src={partner2} className={classes.partner}/></a>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default VaccineTrace;