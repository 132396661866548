import React from 'react';
import classes from './style.module.scss';

const Terms = () => {
    return (
        <div>
            <div className={classes.pageBanner}>
                <h2>Terms of Service</h2>
            </div>
            <div className={classes.pageContainer}>
                <p>PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS. IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</p>
                <div className={classes.title}></div>
                <p>Effective Date: February 7, 2019</p>
                <p>This website is operated by BlockTEST LLC, headquartered at 1000 Massachusetts Avenue, Cambridge, Massachusetts 02138 in the United States, and referred to as “BlockTEST,” “we,” “us,” and “our” below.  These Terms and Conditions (the “Terms”) govern your use of BlockTEST.com and Greenhouse.BlockTEST.com; all communication tools we provide, including messaging, forums, and other content delivery methods; our products, services, data, content, information, and applications (collectively, “BlockTEST Services”) and constitute a binding Agreement between you (“You” and “Your”) and us regarding the BlockTEST Services. By using or accessing the BlockTEST Services in any way, You accept and agree to be bound by all of these Terms. These Terms will remain in effect at all times while You use BlockTEST Services and with respect to all information obtained from or through BlockTEST Services. These Terms include the provisions in this document, as well as those in the <a target="_blank" href="/privacy-policy">Privacy Policy</a> In these Terms, the words “include” or “including” mean “including but not limited to”, and examples are for illustration purposes and are not limiting. If you have any questions, comments, or concerns regarding these terms or the BlockTEST Services, please contact us.</p>
                <div className={classes.title}>1. Will these Terms ever change?</div>
                <p>We are constantly trying to improve our Services, so these Terms may need to change along with the BlockTEST services. We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on BlockTEST.com, by sending you an email, or by some other means. Changes will not apply retroactively and will become effective no sooner than 14 days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the BlockTEST services. If you use the BlockTEST services in any way after a change to the Terms is effective, that means you agree to all of the changes.</p>
                <p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>
                <div className={classes.title}>2. What about my privacy?</div>
                <p>BlockTEST takes the privacy of its users very seriously. For the current BlockTEST Privacy Policy, please click <a target="_blank" href="/privacy-policy">here</a>.</p>
                <div className={classes.title}>3. What are the basics of using BlockTEST?</div>
                <p>You may be required to sign up for an account, and select a password and username (“BlockTEST User ID”). You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your BlockTEST User ID a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission. In the spirit of fairness, you may not have or control more than one active BlockTEST User ID and participate in Challenges (defined below). If we determine that you are operating under more than one BlockTEST User ID, we may disqualify you from any Challenge without notice and revoke access to your BlockTEST User ID.</p>
                <p>You represent and warrant that you are of legal age to form a binding contract. If you are not of legal age to form a binding contract, please see the <a target="_blank" href="/privacy-policy">Privacy Policy</a> and contact us at <a href="mailto:name@email.com">contact@blocktest.net</a> for more information on the parental consent process. You must either be of legal age to form a binding contract or have completed the parental consent process to use the BlockTEST services.</p>
                <p>If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity), but you are still required to sign up each individual user from your organization with a BlockTEST User ID. You are not allowed to allow multiple individuals to operate under one BlockTEST User ID, even if you are an organization or entity.</p>
                <p>You will only use the BlockTEST services for your own internal, personal, non-commercial use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the BlockTEST services is prohibited by applicable laws, then you aren’t authorized to use the BlockTEST services. We are not responsible if you use the BlockTEST services in a way that breaks the law.</p>
                <p>You will keep all your registration information accurate and current. You will not share your account or password with anyone, and you must protect the security of your account and your password. You’re responsible for any activity associated with your account.</p>
                <div className={classes.title}>4. Are there any additional restrictions on my use of the BlockTEST services?</div>
                <p>Yes. Unless otherwise specified in writing by BlockTEST, BlockTEST does not intend uses of the BlockTEST services to create obligations under the Health Insurance Portability and Accountability Act of 1996 as it may be amended from time to time, and any regulations issued under it (“HIPAA”), and makes no representations that the BlockTEST services satisfy HIPAA requirements. If you are or become a Covered Entity or Business Associate, as defined in HIPAA, you will not use the BlockTEST services for any purpose or in any manner involving Protected Health Information (as defined in HIPAA) unless you have received prior written consent for such use from BlockTEST.</p>
                <p>You represent, warrant, and agree that you will not contribute any Content or User Submission or otherwise use the BlockTEST services or interact with the BlockTEST services in a manner that:</p>
                <ol>
                    <li>Infringes or violates the intellectual property rights or any other rights of anyone else (including BlockTEST), including privacy and data protection rights, or creates a liability for BlockTEST;</li>
                    <li>Violates any law or regulation, including any applicable export control laws;</li>
                    <li>Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
                    <li>Jeopardizes the security of your BlockTEST account or anyone else’s (such as allowing someone else to log into the BlockTEST services as you);</li>
                    <li>Attempts, in any manner, to obtain the password, account, or other security information from any other user;</li>
                    <li>Violates the security of any computer network, or cracks any passwords or security encryption codes;</li>
                    <li>Runs Maillist, Listserv, any form of auto-responder or “spam” on the BlockTEST services, or any processes that run or are activated while you are not logged into the BlockTEST services, or that otherwise interfere with the proper working of the BlockTEST services (including by placing an unreasonable load on the BlockTEST services’ infrastructure);</li>
                    <li>“Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the BlockTEST services or Content (through use of manual or automated means);</li>
                    <li>Copies or stores any significant portion of the Content;</li>
                    <li>Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the BlockTEST services.</li>
                    <li>Processes or stores any data that is subject to the International Traffic in Arms Regulations maintained by the U.S. Department of State.</li>
                </ol>
                <p>A violation of any of the foregoing is grounds for termination of your right to use or access the BlockTEST services. We reserve the right to remove any Content or User Submissions from the BlockTEST services at any time, for any reason (including if someone alleges you contributed that Content in violation of these Terms), and without notice.</p>
                <div className={classes.title}>5. What are my rights with BlockTEST?</div>
                <p>The materials displayed or performed or available on or through the BlockTEST services, including text, graphics, data, articles, photos, images, illustrations, and User Submissions (collectively, the “Content”), are protected by copyright and other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the BlockTEST services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including BlockTEST’s) rights.</p>
                <p>You understand that BlockTEST owns the BlockTEST services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the BlockTEST services.</p>
                <p>The BlockTEST Services may allow you to copy or download certain Content; please remember that just because this functionality exists, doesn’t mean that all the restrictions above don’t apply — they do!</p>
                <p>A notice for U.S. Federal Agency Users: The BlockTEST services were developed solely at private expense and are commercial computer software and related documentation within the meaning of the applicable Federal Acquisition Regulations and their agency supplements.</p>
                <div className={classes.title}>6. Who is responsible for what I see and do on the BlockTEST services?</div>
                <p>Any information or content publicly posted or privately transmitted through the BlockTEST services is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk. We aren’t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you release us from all liability for you having acquired or not acquired Content through the BlockTEST services. We can’t guarantee the identity of any users with whom you interact in using the BlockTEST services and are not responsible for which users gain access to the BlockTEST services.</p>
                <p>You are responsible for all Content you contribute to the BlockTEST services, and you represent and warrant you have all rights necessary to do so.</p>
                <p>The BlockTEST services may contain links, information or connections to third party websites or services that are not owned or controlled by BlockTEST. When you access third party websites or engage with third party services, you accept that there are risks in doing so, and that BlockTEST is not responsible for such risks. We encourage you to be aware when you leave the BlockTEST services and to read the terms and privacy policy of each third party website or service that you visit or utilize.</p>
                <p>BlockTEST has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the BlockTEST services. In addition, BlockTEST will not and cannot monitor, verify, censor or edit the content of any third party site or service. By using the BlockTEST services, you release and hold us harmless from any and all liability arising from your use of any third party website or service.</p>
                <p>Your interactions with organizations or individuals found on or through the BlockTEST services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that BlockTEST will not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings</p>
                <p>If there is a dispute between participants on this site, or between users and any third party, you agree that BlockTEST is under no obligation to become involved. If you have a dispute with one or more other users, you release BlockTEST, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes or our Services.</p>
                <div className={classes.title}>7. What are the rules for Challenges on BlockTEST?</div>
                <p>Subject to these Terms and any contract with BlockTEST, a user may post (“Host User”) a challenge on the BlockTEST services (“Challenge”) for other users to participate in such Challenge (“Participant User”). Challenges are subject to separate Challenge Rules as defined below. Challenges exclude games of chance, and you may not use or attempt to use the Service to host any such game of chance.</p>
                <p>Challenges are run according to rules that describe participation guidelines, which the Host User must display within the Challenge description and/or Challenge scenarios. Such rules must comply with all applicable laws and these Terms (collectively, “Challenge Rules”). Certain rights granted in the Challenge Entries are described in Section 8 below.</p>
                <p>Subject to the Challenge Rules, Participant Users may collaborate as a team as long as each such Participant User confirms its team membership through the Service and does not participate on more than one team for a specific Challenge (“Team”). To be clear, you may not participate on more than one team per Challenge. Subject to the Challenge Rules, Teams may, however, merge with other Teams, but the merged Team will be responsible for all past entries by the component Teams.</p>
                <p>A Challenge creates a direct relationship between a Host User and a Participant User, and BlockTEST will have no liability for any actions or content of a Host User or a Participant User. BlockTEST may provide a Host User with a template for the Challenge Rules, but such template is provided without any warranty whatsoever and the Host User is solely responsible for its Challenge Rules. It is the sole responsibility of the Host User to ensure that the Challenge Rules comply with applicable law.</p>
                <p>You acknowledge and agree that BlockTEST may, without any liability but without any obligation to do so, remove or disqualify a Participant User, a Host User or a Challenge if BlockTEST believes that such Participant User, Challenge or Host User are in violation these Terms or otherwise pose a risk to BlockTEST, the Service or another user of the Service.</p>
                <p>Regardless of anything to the contrary, Participant Users acknowledge and agree that BlockTEST and Host Users have no obligation to hold a Challenge Entry in confidence or otherwise restrict their activities based on receipt of such Challenge Entry. BlockTEST has no obligation to become involved in disputes between users (for example, between a Participant User and a Host User) or between users and any third party relating the use of the BlockTEST services. BlockTEST does not oversee Challenges and does not endorse any content users submit to the BlockTEST services. When you host or participate in a Challenge, you release BlockTEST from claims, damages, and demands of every kind — known or unknown, suspected or unsuspected, disclosed or undisclosed — arising out of or in any way related to such disputes and the BlockTEST services. All content you access or submit via the BlockTEST services is at your own risk. You are solely responsible for any resulting damage or loss to any party.</p>
                <div className={classes.title}>8. Do I have to grant any licenses to BlockTEST or to other users?</div>
                <p>You retain ownership of all Content you submit, post, display, or otherwise make available on the BlockTEST Services.</p>
                <p>Anything you post, upload, share, store, or otherwise provide through the BlockTEST services is your “User Submission.” Some User Submissions are viewable by other users. To display your User Submissions on the BlockTEST services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions. Please note that all of the following licenses are subject to our <a target="_blank" href="/privacy-policy">Privacy Policy</a> to the extent they relate to User Submissions that are also your personal information.</p>
                <p>For all User Submissions, you grant BlockTEST a license to translate, modify (for technical purposes, for example making sure your content is viewable on an iPhone as well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to enable us to operate the BlockTEST services, as described in more detail below. You acknowledge and agree that BlockTEST, in performing the required technical steps to provide the BlockTEST services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of communication networks, devices, services, or media, and the licenses you grant under these Terms include the rights to do so. You also agree that all of the licenses you grant under these Terms are royalty-free, perpetual, irrevocable, and worldwide. These are licenses only — your ownership in User Submissions is not affected.</p>
                <p>If you store a User Submission in your own personal BlockTEST account, in a manner that is not viewable by any other user except you (a “Personal User Submission”), you grant BlockTEST the license stated in the second paragraph of this Section 8, as well as a license to display, perform, and distribute your Personal User Submission for the sole purpose of making that Personal User Submission accessible to you and providing the BlockTEST services necessary to do so. If you share a User Submission in a manner that only allows certain specified users to view it (for example, a private message to one or more other users) (a “Limited Audience User Submission”), then you grant BlockTEST the license stated in the second paragraph of this Section 8, as well as a license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of making that Limited Audience User Submission accessible to such other specified users, and providing the BlockTEST services necessary to do so. Also, you grant such other specified users a license to access that Limited Audience User Submission, and to use and exercise all rights in it, as permitted by the functionality of the BlockTEST services.</p>
                <p>If you share a User Submission publicly on the BlockTEST services or in a manner that allows more than just you or certain specified users to view it, or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements, enhancements, or feature requests relating to the BlockTEST services (each a “Public User Submission”), then you grant BlockTEST the license stated in the second paragraph of this Section 8, as well as a license to display, perform, and distribute your Public User Submission for the purpose of making that Public User Submission accessible to all BlockTEST users and providing the BlockTEST services necessary to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in connection with the BlockTEST services for any purpose. Also, you grant all other users of the BlockTEST services a license to access that Public User Submission, and to use and exercise all rights in it, as permitted by the functionality of the BlockTEST services.</p>
                <p>If you are a Participant User and submit an entry to a Challenge (“Challenge Entry”), then you grant BlockTEST the license stated in the second paragraph of this Section 8, as well as a license to display, perform, and distribute your Challenge Entry for the purpose of making that Challenge Entry accessible to the Host User, and providing the BlockTEST services necessary to do so. Also, you grant such Host User a limited license to access and use the Challenge Entry solely for the purposes of evaluating the Challenge Entry under the Challenge Rules. If you win a Challenge, your Challenge Entry for such Challenge will be subject to further licensing as stated in the Challenge Rules, but other than the limited licenses stated in these Terms, the intellectual property rights in your Challenge Entries will not be transferred or licensed to the Challenge Sponsor or Host User.</p>
                <p>If you and BlockTEST agree (separate from these Terms) that BlockTEST will assist you in setting up and managing your Challenge, then in addition to the licenses stated above you also grant BlockTEST a royalty-free, perpetual, irrevocable, and worldwide license to set up and manage your Challenge, including your User Submissions for the Challenge. BlockTEST will have no liability regarding the applicable Challenge, Content or User Submissions and the terms in Section 15 below will apply, except if you and BlockTEST have executed a separate written agreement governing Challenges or services (“Existing Agreement”), in which case the Existing Agreement will govern the Challenge.</p>
                <div className={classes.title}>9. What if I see something on the BlockTEST services that infringes my copyright?</div>
                <p>You may have heard of the Digital Millennium Copyright Act (the “DMCA”), as it relates to online service providers, like BlockTEST, being asked to remove material that allegedly violates someone’s copyright. We respect others’ intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers. To learn more about the DMCA, click <a href="https://www.copyright.gov/legislation/dmca.pdf" target="_blank">here</a>.</p>
                <div className={classes.title}>10. Will BlockTEST ever change the BlockTEST services?</div>
                <p>We’re always trying to improve the BlockTEST services, so they may change over time. We may suspend or discontinue any part of the BlockTEST services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the BlockTEST services. We’ll try to give you notice when we make a material change to the BlockTEST services that would adversely affect you, but this isn’t always practical.</p>
                <div className={classes.title}>11. Does BlockTEST cost anything?</div>
                <p>The BlockTEST Services may be free or we may charge a fee for using the BlockTEST services. If you are using a free version of the BlockTEST services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services.</p>
                <ol>
                    <li><b>Paid Services.</b> Certain of our Services may be subject to payments now or in the future (the “Paid Services”). Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of these Terms.</li>
                    <li><b>Billing.</b> We use a third-party payment processor (the “Payment Processor”) to bill you through a payment account linked to your Account on the BlockTEST services (your “Billing Account”) for use of the Paid Services. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms. We are not responsible for errors by the Payment Processor. By choosing to use Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that it makes even if it has already requested or received payment.</li>
                    <li><b>Payment Method.</b> The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.</li>
                    <li><b>Recurring Billing.</b> Some of the Paid Services may consist of an initial period, for which there is a one-time charge, followed by recurring period charges as agreed to by you. By choosing a recurring payment plan, you acknowledge that such Services have an initial and recurring payment feature and you accept responsibility for all recurring charges before cancellation. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE WE REASONABLY COULD ACT.</li>
                    <li><b>Current Information Required.</b> YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSOR IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE HERE. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS STATED ABOVE.</li>
                    <li><b>Change in Amount Authorized.</b> If the amount to be charged to your Billing Account varies from the amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes), you have the right to receive, and we will provide, notice of the amount to be charged and the date of the charge before the scheduled date of the transaction. Any agreement you have with your payment provider will govern your use of your Payment Method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle.</li>
                    <li><b>Auto-Renewal for Subscription Services.</b> Unless you opt out of auto-renewal, which can be done through your Account Settings, any Subscription Services you have signed up for will be automatically extended for successive renewal periods of the same duration as the subscription term originally selected, at the then-current non-promotional rate. To change or resign your Subscription Services at any time, go to Account Settings . If you terminate a Subscription Service, you may use your subscription until the end of your then-current term; your subscription will not be renewed after your then-current term expires. However, you won’t be eligible for a prorated refund of any portion of the subscription fee paid for the then-current subscription period.</li>
                    <li><b>Reaffirmation of Authorization.</b> Your non-termination or continued use of a Paid Service reaffirms that we are authorized to charge your Payment Method for that Paid Service. We may submit those charges for payment and you will be responsible for such charges. This does not waive our right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially selected to use the Paid Service.</li>
                </ol>
                <div className={classes.title}>12. What if I want to stop using BlockTEST?</div>
                <p>If you do not have any active Challenges that you are hosting, then you’re free to stop using the Service at any time; please refer to our <a target="_blank" href="/privacy-policy">Privacy Policy</a>, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services. If you are hosting active Challenges then you should complete the Challenges, in full, before you stop using the BlockTEST services.</p>
                <p>BlockTEST is also free to terminate (or suspend access to) your use of the BlockTEST services or your account, for any reason in our discretion, including your breach of these Terms. BlockTEST has the sole right to decide whether you are in violation of any of the restrictions in these Terms.</p>
                <p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.</p>
                <p>Provisions that, by their nature, should survive termination of these Terms will survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us.</p>
                <div className={classes.title}>13. What else do I need to know?</div>
                <p><b>Warranty Disclaimer.</b> Neither BlockTEST nor its licensors or suppliers makes any representations or warranties concerning any content contained in or accessed through the BlockTEST services (including Challenges), and we will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the BlockTEST services. We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the BlockTEST services. Products and services purchased or offered (whether or not following such recommendations and suggestions) through the BlockTEST services are provided “AS IS” and without any warranty of any kind from BlockTEST or others (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY BLOCKTEST (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
                <p><b>Limitation of Liability.</b> TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE) WILL BLOCKTEST (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL OR INCIDENTAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO BLOCKTEST IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
                <p><b>Indemnity.</b> To the fullest extent allowed by applicable law, you will defend, indemnify and hold BlockTEST, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims (including from other users) relating to (a) your submissions to the BlockTEST services including any Content, User Submissions or Challenges, (b) your use of the BlockTEST services (including any actions taken by a third party using your account), and (c) your violation of these Terms.</p>
                <p><b>Assignment.</b> You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without BlockTEST’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>
                <div className={classes.title}>14. About These Terms</div>
                <p>These Terms control the relationship between BlockTEST and you. They do not create any third party beneficiary rights.</p>
                <p>If you do not comply with these Terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).</p>
                <p>If it turns out that a particular term is not enforceable, this will not affect any other part of the Terms.</p>
                <p>The laws of Massachusetts, USA, without regard to conflicts of laws provisions that would lead to the application of different law, will apply to any disputes arising out of or relating to these Terms or the BlockTEST services. All claims arising out of or relating to these Terms or the BlockTEST services will be resolved by arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules, or such other arbitration body as required by law, rule or regulation, and judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction. The arbitration will be conducted in the English language before a single arbitrator in Cambridge, Massachusetts, and you and BlockTEST consent to personal jurisdiction in those courts.</p>
            </div>
        </div>
    )
}

export default Terms;