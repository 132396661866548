import React from 'react';
import './sass/App.scss';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home/Home';
import Solutions from './Components/Solutions/Solutions';
import About from './Components/About/About';
import Technology from './Components/Technology/Technology';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import VaccineTrace from './Components/Solutions/VaccineTrace/VaccineTrace';
import ProofService from './Components/Solutions/ProofService/ProofService';
import Financing from './Components/Solutions/Financing/Financing';
import Terms from './Components/Terms-Policy/Terms';
import Policy from './Components/Terms-Policy/Policy';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/solutions" component={Solutions} />
          <Route path="/about" exact component={About} />
          <Route path="/technology" exact component={Technology} />
          <Route path="/vaccine-traceability" exact component={VaccineTrace} /> 
          <Route path="/proof-of-service" exact component={ProofService} /> 
          <Route path="/financing-maritime-trade" exact component={Financing} />
          <Route path="/technology" exact component={Technology} />
          <Route path="/terms-of-service" exact component={Terms} />
          <Route path="/privacy-policy" exact component={Policy} />
          <Redirect from="/" to="/home" />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
